import { Modal, productInfo, useFijoState } from '~/store/States/fijo';
import { useHookstate } from '@hookstate/core';
import { useEffect, useState } from 'react';
import { useDetalleFijo } from '~/store/Query/api-fijo';

export default function DetallePlanFijo({ item }) {
  const fijoState = useFijoState();
  const textoState = useHookstate(fijoState.texto.nested(item.type));
  const [modal, setModal] = useState(false);
  const detalle = useDetalleFijo(item.part_number, modal);
  const { name, format } = productInfo(item);
  const fullname = name.split('\n').map((str, index) => (
    <p key={index} className="m-0">
      {str}
    </p>
  ));
  const imgDetalle = item.attributes.find(
    (attr) =>
      attr.identifier.indexOf('attr-main-fijo') === 0 && attr.value === 'SI',
  );
  const price = format.split('.');
  const images = item.images || [];
  const fechaImg = images
    .map((img) => {
      const idx = img.indexOf('?') + 1;
      return idx > 0 ? img.substring(idx) : '';
    })
    .sort((a, b) => (a < b ? 1 : a > b ? -1 : 0))[0];

  useEffect(() => {
    if (modal && detalle.data?.ok) {
      const info = detalle.data.body.sections?.[0]?.attributes ?? [];
      Modal({
        customClass: {
          popup: 'plan-fijo-pop',
          htmlContainer: 'plan-fijo-container',
        },
        html: (
          <div className="has-bootstrap modal-planes-fijos">
            <div className="mt-4 p-3 modal-body">
              <div className="row m-0 p-0">
                <div className="col-12 p-0">
                  <h5 className="modal-title text-center bg-blue text-light h3 roboto-regular">
                    {fullname}
                  </h5>
                </div>
              </div>
              <div className="row p-0 m-0 px-3 py-3">
                <div className="col-md-2 col-0" />
                <div className="col-md-8 col-12">
                  {imgDetalle && (
                    <div className="row">
                      <div className="col-12 text-center py-3">
                        <img
                          className="m-auto"
                          src={`${
                            process.env.NEXT_PUBLIC_ESPOTS
                          }/charsmain/${imgDetalle.identifier.replace(
                            /-/g,
                            '_',
                          )}.jpg?${fechaImg}`}
                          alt={imgDetalle.identifier}
                        />
                      </div>
                    </div>
                  )}
                  {info.map((det, i) => (
                    <div key={i} className="py-1">
                      <i className="fas fa-check text-dark" />
                      <span className="roboto-light">&nbsp;{det}</span>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className="modal-footer d-flex justify-content-center">
              <div className="col-12 price-cont my-2">
                <div className="d-flex justify-content-center">
                  <div className="mobile-price">
                    <span className="quetzal">
                      {process.env.NEXT_PUBLIC_CURRENCY_SYMBOL2}
                    </span>
                    {price[0]}
                    <span className="decimal">.{price[1]}</span>
                    <span className="mobile-price-text space-pre">
                      {textoState.precioSufijo.value}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ),
      }).then(() => {
        setModal(false);
      });
    }
  }, [modal, detalle.isSuccess]);

  const onDetalle = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setModal(true);
  };

  return <span onClick={onDetalle}>Ver más detalle</span>;
};
