import helpers from '~/helpers/product';
import { cuotaFormat, currencyFormat, imgFormat } from '~/helpers/formatter';
import { Link } from '#/renderer/Link';
import ESimIndicator from '~/components/ESimIndicator/ESimIndicator';
import DetallePlanFijo from '~/containers/Home/DetallePlanFijo';

export default function ProductoDestacado({ prod, category, planes, config }) {
  helpers.ordenar(prod);
  let attrMap;
  const item = prod.items.find((it) => {
    attrMap = helpers.convertAttributes(it.attributes);
    return attrMap['attr-conf-destacado'] === 'SI';
  });
  if (!item) {
    return null;
  }
  const tipoProducto = attrMap['attr-conf-tipoproducto'];
  const cuotas = attrMap['attr-conf-pagocuotas'];
  const textoCuota = attrMap['attr-texto-cuotas'];
  const endpoint =
    tipoProducto === 'OTRO'
      ? '/productx/'
      : tipoProducto === 'POSPAGO'
      ? '/productp/'
      : '/product/';
  const fullname = item.locales[process.env.NEXT_PUBLIC_LOCALE].name
    .replace(/<br>|<br\/>|\\n/g, '\n')
    .split('\n')
    .map((str, index) => <h3 key={index}>{str}</h3>);
  let img = 'Producto sin imagen';
  if (item && item.images && item.images.length) {
    img = (
      <img
        src={imgFormat(
          '210x180',
          `${process.env.NEXT_PUBLIC_CDN_PATH}${item.images[0]}`,
        )}
        alt="products"
        loading='lazy'
      />
    );
  }

  let label;
  let labelContado = '';
  let labelCuota = '';
  let labelPlan;
  let price;
  let priceclass = '';
  let sufix;
  let detail;
  let lblButton = 'Comprar';
  let currencySymbol = process.env.NEXT_PUBLIC_CURRENCY_SYMBOL;
  let incluido = false;
  let link = `${endpoint}${
    item.locales[process.env.NEXT_PUBLIC_LOCALE].seo.name
  }/${prod.part_number}`;
  let aceptaCuotas = false;
  let formatoCuotas = '';
  let precioMinimoCuotas = 0;
  let contado = 'Precio de contado';
  let mostrarCuotas = true;
  switch (tipoProducto) {
    case 'PLAN': {
      label = 'Precio del plan';
      price = attrMap['attr-conf-precio-mensual'];
      sufix = <span>&nbsp;al mes</span>;
      lblButton = 'Contratar servicio';
      currencySymbol = process.env.NEXT_PUBLIC_CURRENCY_SYMBOL2;
      const redes = item.attributes
        .filter(
          (attr) =>
            /attr-redsocial/.test(attr.identifier) &&
            attr.value.toUpperCase() === 'SI',
        )
        .map((attr, idx) => (
          <img
            className="red-social"
            key={idx}
            src={`${
              process.env.NEXT_PUBLIC_CDN_PATH
            }/espots/socialmedia/${attr.identifier.replace(/-/g, '_')}.jpg`}
            alt={attr.identifier}
            loading='lazy'
          />
        ));
      detail = redes.length > 0 && (
        <>
          <div className="d-flex align-items-sm-center gap-2 flex-column flex-sm-row">
            <div className="fw-bold">Redes ilimitadas</div>
            <div className="d-flex gap-1 justify-content-between">{redes}</div>
          </div>
          <div>
            <Link to={link}>Ver más detalle</Link>
          </div>
        </>
      );
      break;
    }
    case 'PLANFIJO': {
      label = 'Precio del plan';
      price = item.pricing[process.env.NEXT_PUBLIC_CURRENCY].offer_price / 100;
      sufix = <span>&nbsp;al mes</span>;
      lblButton = 'Contratar servicio';
      currencySymbol = config.monedaPospago?.activo ? config.monedaPospago.simbolo : process.env.NEXT_PUBLIC_CURRENCY_SYMBOL;
      const code =
        attrMap['attr-conf-friendly-url'] ||
        item.locales[process.env.NEXT_PUBLIC_LOCALE].seo.name ||
        item.part_number;
      link = `/categories/${category.referrer}?plan=${code}`;
      const internet = attrMap['attr-chars-titulo-internet'];
      const tv = attrMap['attr-chars-titulo-tv'];
      const llamadas = attrMap['attr-chars-titulo-llamadas'];
      const mensajes = attrMap['attr-chars-titulo-mensajes'];
      detail = (
        <div className="d-flex gap-2 flex-column destacado--detail">
          <div className="fw-bold">
            {(internet || tv || llamadas || mensajes) && 'Beneficios'}
          </div>
          <div className="d-flex gap-1 justify-content-between">
            {internet && (
              <div>
                <img
                  className=""
                  src={`${process.env.NEXT_PUBLIC_ESPOTS}/media/attr_detalle_internet_renovacion.png`}
                  alt="detalle internet"
                  loading='lazy'
                />
                <span
                  style={{ TextDecoderation: 'underline', color: '#0097a9' }}
                >
                  {internet}
                </span>
              </div>
            )}
            {tv && (
              <div>
                <img
                  className=""
                  src={`${process.env.NEXT_PUBLIC_ESPOTS}/media/attr_detalle_tv_renovacion.png`}
                  alt="detalle tv"
                  loading='lazy'
                />
                <span>{tv}</span>
              </div>
            )}
            {llamadas && (
              <div>
                <img
                  className=""
                  src={`${process.env.NEXT_PUBLIC_ESPOTS}/media/attr_detalle_llamadas_renovacion.png`}
                  alt="detalle llamadas"
                  loading='lazy'
                />
                <span>{llamadas}</span>
              </div>
            )}
            {mensajes && (
              <div>
                <img
                  className=""
                  src={`${process.env.NEXT_PUBLIC_ESPOTS}/media/attr_detalle_sms_renovacion.png`}
                  alt="detalle sms"
                  loading='lazy'
                />
                <span>{mensajes}</span>
              </div>
            )}
          </div>
          <div>
            <DetallePlanFijo item={item} />
          </div>
        </div>
      );
      break;
    }
    case 'POSPAGO': {
      label = 'Precio del equipo';
      currencySymbol = config.monedaPospago?.activo ? config.monedaPospago.simbolo : process.env.NEXT_PUBLIC_CURRENCY_SYMBOL;
      if (config.textoPospago?.terminal) {
        label = config.textoPospago.terminal.textoPrecio ?? label;
        contado = config.textoPospago.terminal.textoContado ?? contado;
        mostrarCuotas =
          config.textoPospago.terminal.mostrarCuotas ?? mostrarCuotas;
      }
      const attrDefaultPlan =
        attrMap['attr-conf-default-plan']?.replace(/\./g, '_') || '';
      const plazo = attrMap['attr-conf-default-plazo'];
      const prices =
        (attrDefaultPlan &&
          planes?.[attrDefaultPlan] &&
          item.pricing.pospago?.[attrDefaultPlan]) ||
        item.pricing.pospago?.[
          Object.keys(item.pricing.pospago)
            .filter((pl) => {
              if (planes?.[pl]) {
                item.pricing.pospago[pl].plan = pl;
                return true;
              }
              return false;
            })
            .sort((p1, p2) => {
              let pl1 = plazo;
              if (!pl1 || !item.pricing.pospago[p1][pl1]) {
                pl1 = Object.keys(item.pricing.pospago[p1]).sort(
                  (a, b) => b - a,
                )[0];
              }
              let pl2 = plazo;
              if (!pl2 || !item.pricing.pospago[p2][pl2]) {
                pl2 = Object.keys(item.pricing.pospago[p2]).sort(
                  (a, b) => b - a,
                )[0];
              }
              return (
                item.pricing.pospago[p1][pl1].offer_price -
                item.pricing.pospago[p2][pl2].offer_price
              );
            })[0]
        ];

      if (prices) {
        let first = plazo;
        if (!first || !prices[first]) {
          first = Object.keys(prices).sort((a, b) => b - a)[0];
        }
        price = prices[first].offer_price;
        const namePlan = planes?.[prices.plan || attrDefaultPlan];
        labelPlan = namePlan && (
          <span className="product--plan-label">
            En un plan de{' '}
            <span className="product--plan-description">{namePlan}</span>
          </span>
        );
      } else {
        price =
          item.pricing[process.env.NEXT_PUBLIC_CURRENCY].offer_price / 100;
      }
      incluido = +config.precio_minimo_incluido >= price;
      precioMinimoCuotas = config.precioMinimoCuotas || 0;
      aceptaCuotas = price >= precioMinimoCuotas;
      formatoCuotas = config.formatoCuotas || '';
      break;
    }
    default:
      label = 'Precio del equipo';
      price = item.pricing[process.env.NEXT_PUBLIC_CURRENCY].offer_price / 100;
      precioMinimoCuotas = config.precioMinimoCuotas || 0;
      aceptaCuotas = price >= precioMinimoCuotas;
      formatoCuotas = config.formatoCuotas || '';
  }
  if (cuotas === 'SI' && textoCuota && aceptaCuotas && mostrarCuotas) {
    label = attrMap['attr-titulo-cuotas'] || `${label} financiado`;
    const precioCuota = +textoCuota;
    if (Number.isNaN(precioCuota)) {
      labelCuota = (
        <p
          className="price-cuota"
          dangerouslySetInnerHTML={{ __html: textoCuota }}
        />
      );
    } else {
      const cuota = currencyFormat(price / precioCuota, 1).split('.');
      labelCuota = cuotaFormat(cuota, textoCuota, formatoCuotas);
    }
    labelContado = <p className="price-before mb-0">{contado}</p>;
    priceclass = 'cuota';
  }
  price = currencyFormat(price, 1).split('.');

  return (
    <div className="boxCard" style={{ position: 'relative' }}>
      <div className="boxCard--title">{fullname}</div>
      <div className="boxCard--img">
        <picture>{img}</picture>
      </div>
      {prod.hasVarianWithEsim && (
        <ESimIndicator
          label={prod.esimIndicator?.label}
          image={prod.esimIndicator?.image}
          top="var(--esim-top, 116px)"
        />
      )}
      <div className={`boxCard--price ${priceclass}`}>
        <div className="lbl">{label}</div>
        {incluido && tipoProducto === 'POSPAGO' ? '' : labelCuota}
        {labelContado}
        <p className="price">
          {incluido ? (
            <span>INCLUIDO</span>
          ) : (
            <>
              <span className="currency">
                {currencySymbol}
              </span>
              <span>{price[0]}.</span>
              <span className="decimal">{price[1]}</span>
              {sufix}
            </>
          )}
        </p>
        {labelPlan}
      </div>
      <div className="boxCard--icons">{detail}</div>
      <div className="boxCard--button">
        <Link className="link" to={link}>
          {lblButton}
        </Link>
      </div>
    </div>
  );
}
